import React from 'react';
import BreadcrumbSchema from '../../components/BreadcrumbSchema';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FaExclamationTriangle } from 'react-icons/fa';
import ServiceTemplate from '../../components/service/ServiceTemplate';

const TroubleshootingService = ({ data }) => {
  const seoData = {
    title: '急なトラブル対応｜奈良のホームページ制作なら｜インヴォルブ',
    description:
      '「サイトが突然見れなくなった」「メールが届かない」など、急なWeb関連のトラブルを迅速に対応・解決します。',
    pageUrl: 'https://www.involve-in.jp/service/troubleshooting',
    ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
  };

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'サービス一覧', url: 'https://www.involve-in.jp/service/' },
    { name: '急なトラブル対応', url: seoData.pageUrl },
  ];

  const works = data.allContentfulCaseStudy.nodes;

  return (
    <>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <ServiceTemplate
        title="急なトラブル対応"
        subtitle="すぐに対応します"
        icon={FaExclamationTriangle}
        description="「サイトが突然見れなくなった」「メールが届かない」など、急なWeb関連のトラブルを迅速に対応・解決します。"
        seo={seoData}
        breadcrumbs={breadcrumbList}
        benefits={[
          {
            title: '迅速対応',
            description: 'すぐに問題を診断し、早期解決を目指します。',
          },
          {
            title: '専門知識不要',
            description: '技術的な用語がわからなくても安心してご相談ください。',
          },
          {
            title: '事前見積もり',
            description: '料金の目安を伝えてから対応を開始するので安心です。',
          },
        ]}
        flow={[
          {
            title: 'トラブル内容の確認',
            duration: '即日',
            description: '問題の詳細をお伺いし、発生状況を整理します。',
          },
          {
            title: '初期診断',
            duration: '1日',
            description: 'サイトやサーバーの状態を確認し、原因を特定します。',
          },
          {
            title: '対応・修正',
            duration: '即日〜',
            description: '可能な限り迅速に修正し、トラブルを解決します。',
          },
          {
            title: '再発防止策の提案',
            duration: null,
            description: '今後のトラブルを防ぐための対策を提案します。',
          },
        ]}
        preparation={[
          {
            title: 'トラブルの内容を整理',
            description: '発生している問題やエラーメッセージを確認。',
          },
          {
            title: '契約情報の確認',
            description:
              'サーバーやドメインの契約状況を把握しておくとスムーズです。',
          },
          {
            title: '影響範囲の特定',
            description: 'どのページ・機能に問題があるのかを整理。',
          },
          {
            title: '連絡手段の確保',
            description: '緊急時の連絡手段（メール・電話）を事前に確認。',
          },
        ]}
        works={works.map((work) => ({
          title: work.title,
          image:
            work.image && work.image.length > 0
              ? getImage(work.image[0].gatsbyImageData)
              : null,
        }))}
        price="¥10,000〜"
        cta="今すぐ相談"
      />
    </>
  );
};

export const query = graphql`
  query {
    allContentfulCaseStudy(sort: { publishDate: DESC }) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;

export default TroubleshootingService;
